<template>
  <div class="mediaReviewPhotoSessionOverview">
    <div
      class="p-2 border mb-2 mr-2 text-center rounded"
    >
      <div class="imagePlaceholderWrapper rounded mb-1">
        <img
          v-if="run"
          :src="getImage(run)"
          class="previewImage rounded"
          loading="lazy"
        >
        <ImagePlaceholderSvg
          v-else
          :height="200"
          :width="350"
          :media-preview="true"
          class="previewImage rounded w-100 h-100"
        />
      </div>
      <div>
        <label class="bold mr-2 mb-0">{{ $t('created') }}:</label>
        <span v-if="run && run.createDate">{{ dateTime_dateTimeSeconds(run.createDate) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";

export default {
  name: "MediaReviewPhotoSessionOverview",
  components: {
    ImagePlaceholderSvg: () =>
      import("@/components/Base/ImagePlaceholderSvg.vue")
  },
  mixins: [dateTimeMixin],
  props: {
    run: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {},
  created () { },
  methods: {
    getImage (run) {
      if (run == null) {
        return;
      }
      return "https://api.skiline.cc/php/app/api-helper/0.10/Admin/Video/decoratedRun.php?runId="+run.id+"&apiKey=383zhdd7yJHDOcvmiq563wnmdjuhJ83wdsn";
    }
  }
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.imagePlaceholderWrapper {
  position: relative;
  width: 350px;
  overflow: hidden;
  height: 200px;
}
.imagePlaceholderWrapper > *:not(.itemDuration) {
  position: absolute;
  width: 100%;
  height: auto;
  min-width: auto;
  min-height: 100%;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
